// extracted by mini-css-extract-plugin
export var chatgptCasesExpertiseSection = "V_jg";
export var chatgptCasesSection = "V_jk";
export var chatgptDevExpertiseSection = "V_jl";
export var chatgptIndustriesSection = "V_jd";
export var chatgptPrimeSection = "V_jb";
export var chatgptProcessSection = "V_jh";
export var chatgptQuotesSection = "V_jn";
export var chatgptServicesSection = "V_jc";
export var chatgptTechStackSection = "V_jj";
export var chatgptTrustedSection = "V_jf";
export var chatgptWhyChooseSection = "V_jm";