// extracted by mini-css-extract-plugin
export var chatGptExpertiseCases__between = "dw_B8";
export var chatGptExpertiseCases__card = "dw_Cd";
export var chatGptExpertiseCases__contentAll = "dw_Ck";
export var chatGptExpertiseCases__contentOne = "dw_Ch";
export var chatGptExpertiseCases__contentTwo = "dw_Cj";
export var chatGptExpertiseCases__description = "dw_Cc";
export var chatGptExpertiseCases__imageDesktop = "dw_B9";
export var chatGptExpertiseCases__title = "dw_Cb";
export var chatGptExpertiseCases__wrapperLeft = "dw_Cf";
export var chatGptExpertiseCases__wrapperRight = "dw_Cg";